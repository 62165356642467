import React from "react";
import { Link } from "gatsby";

import Logo from "../images/logos/horizontal.png";
import Mark from "../images/logos/mark.png"
import Button from "./button";

const Header = () => {
  const navigation = [
    { name: "Services", href: "/services" },
    { name: "Insurance", href: "/insurance" },
  ];

  function scroll() {
    console.log('scroll!', document.getElementById('contact-form'))
    let form = document.getElementById('contact-form')

    form.scrollIntoView({ block: 'center' })
  }
  return (
    <header>
      <div className="container mx-auto mt-8">
        <div className="flex py-5 justify-between items-center">
          <div className="flex flex-row gap-8 items-center">
            <Link to="/" class='hidden lg:flex'>
              <img className="h-24 w-auto hidden lg:flex" src={Logo} alt="Logo" />
            </Link>

          <Link to="/" class='lg:hidden'>
              <img class="h-24 w-auto lg:hidden" src={Mark} alt="Logo" href="/" />
            </Link>
          </div>
          <div className="flex sm:flex-row flex-col-reverse gap-6">
            <div className="flex flex-row gap-4 items-center">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 px-4">
                  {item.name}
                </a>
              ))}
            </div>
            <a className="button flex gap-1 items-center justify-center bg-primary-600 text-white font-semibold px-5 py-3 text-body-xs" href="/contact" >CONTACT US</a>
            
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
