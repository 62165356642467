import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allSocialJson {
        nodes {
          id
          name
          href
          icon {
            publicURL
          }
        }
      }
    }
  `);

  let form = {
    name: "Jane",
    email: "",
    phone: "",
    message: ""
  }

  function submit(event) {
    console.log('submit')

    let val = {
      name: document.getElementById('contact-name').value,
      phone: document.getElementById('contact-phone').value,
      email: document.getElementById('contact-email').value,
      message: document.getElementById('contact-message').value
    }

    console.log('val', val)

    fetch('https://cxcxddxx41.execute-api.us-east-1.amazonaws.com/dev/contact-email', {
      method: 'POST',
      body: JSON.stringify(val),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })

    let submitBtn = document.getElementById('message-submit')
    submitBtn.value = "Sent!"

    setTimeout(() => { 
      let name = document.getElementById('contact-name')
      let phone = document.getElementById('contact-phone')
      let email = document.getElementById('contact-email')
      let message = document.getElementById('contact-message')

      name.value = ""
      phone.value = ""
      email.value = ""
      message.value = ""

      submitBtn.value = "Send us a message!"

     }, 3000 )
    
    event.preventDefault()
  }
  return (
    <footer>
      <div className="container mx-auto">
        <div className="mt-2 md:mb-20 mb-10">
          <hr className="text-neutral-300"></hr>
        </div>
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-12">
          <div className="lg:col-span-8 md:pr-24">
            <h3 className="font-display md:text-display-lg text-display-sm font-normal pb-4">
              How can we help?
            </h3>
            <div className='p-2 flex flex-col' id="contact-form">
              <form onSubmit={submit}>
                <div className='md:flex-row flex flex-col justify-between py-1'>
                  <span>Name</span>
                  <input type="text" id="contact-name" defaultValue='' className=' bg-neutral-100 p-2 md:w-1/2'/>
                </div>
                <div className='md:flex-row flex flex-col justify-between py-1'>
                  <span>Phone Number</span>
                  <input type="tel" id="contact-phone" defaultValue="" className=' bg-neutral-100 p-2 md:w-1/2'/>
                </div>
                <div className='md:flex-row flex flex-col justify-between py-1'>
                  <span>Email</span>
                  <input type="email" id="contact-email" defaultValue="" className=' bg-neutral-100 p-2 md:w-1/2'/>
                </div>
                <div>Message</div>
                <div className='w-full'>
                  <textarea id="contact-message" defaultValue="" rows="3" className='w-full bg-neutral-100 p-2'></textarea>
                </div>
                <div className='w-full flex justify-end py-2'>
                  <input type='submit' value='Send us a message!' id="message-submit" className='bg-primary-600 py-3 px-8'/>
                </div>
              </form>
            </div>
            {/* <a
              href="mailto:hello@makingmommies.com"
              className="font-display md:text-display-lg text-display-sm italic text-primary-600 underline">
              Send us a message
            </a> */}
          </div>
          <div className="lg:col-span-4 flex flex-col gap-8 xl:pl-2 lg:pl-2">
            <div className="flex flex-col gap-2">
              <p className="text-display-xs font-display font-normal">
                Phoenix, Arizona
              </p>
              {/* <p className="text-body-sm font-light text-neutral-900">
                962 Fifth Avenue Str, 3rd Floor-Trump Building NY 10006, United
                State.
              </p> */}
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                Email us at
              </p>
              <a
                className="text-display-xs font-display font-normal text-primary-600"
                href="mailto:hello@makingmommies.com">
                hello@makingmommies.com
              </a>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                Feel free to give us a call, <br /> though we may be at a birth...
              </p>
              <a
                className="text-display-xs font-display font-normal text-primary-600"
                href="tel:4802539042">
                (480) 253-9042
              </a>
            </div>
          </div>
        </div>
        <div className="md:my-20 my-10">
          <hr className="text-neutral-300"></hr>
        </div>
        <div className="flex lg:flex-row flex-col gap-8 lg:items-center justify-between md:mb-20 mb-10">
          <div className="text-body-md font-light">
            © {new Date().getFullYear()} Making Mommies, LLC
          </div>
          <div className="flex lg:flex-row flex-col lg:items-center md:gap-6 gap-4">
            <div className="flex flex-row items-center opacity-70">
              <p className="text-body-sm font-semibold tracking-widest text-neutral-700 pr-4">
                CONNECT
              </p>
              <hr className="w-16 text-neutral-700"></hr>
            </div>
            <div className="flex flex-row  items-center gap-6">
              {data.allSocialJson.nodes.map((node) => (
                <a
                  href={node.href}
                  key={node.name}
                  target="_blank"
                  rel="noreferrer">
                  <img
                    className="h-10 w-10"
                    src={node.icon.publicURL}
                    alt={node.name}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
